import { CloseCircleOutlined } from "@ant-design/icons";
import { Col, Empty, Input, Row, Select, Spin, Switch, message } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { apiDeleteUiForm, apiGetUiForms, apiSaveRegistrationGeneral } from "services/dashboardService";
import { isEmpty } from "utils/GlobalFunctions";
import { showFormUiAddModal } from "./FormUI/showFormUiAddModal";
import FormUiTable from "./FormUI/FormUiTable";
const Step1FormTab = (props) => {
  const { Option } = Select;
  const { registrationID, setRegistrationID, settingInfo } =
    props;
  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);

  const [dataList, setDataList] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    loadData();
  }, []);


  const onAdd = async () => {
    var resInfo = await showFormUiAddModal({
      title: "Add UI Element for Step1",
      token: userDataStore.token,
      columns: columns,
      registrationID: registrationID
    });
    if (resInfo == null) return;
    loadData();
  }

  const loadData = () => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("registration_id", registrationID);
    setIsBusy(true);
    apiGetUiForms(params)
      .then((res) => {
        setDataList(res.ui_list);
        setColumns(res.fields_list);
        setIsBusy(false);
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  };
  const updateData = async (info) => {
    console.log(info)
    var resInfo = await showFormUiAddModal({
      title: "Update UI Element for Step1",
      token: userDataStore.token,
      registrationID: registrationID,
      columns: columns,
      info: info
    });
    if (resInfo == null) return;
    loadData();
  }
  const deleteData = (id) => {
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("id", id);
    setIsBusy(true);
    apiDeleteUiForm(params)
      .then((res) => {
        setIsBusy(false);
        loadData();
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });
  }
  return (
    <div>
      <Spin spinning={isBusy}>
        <Row gutter={[16, 16]} style={{ marginTop: 20 }} justify={"space-between"}>
          <Col>
            <div style={{ fontWeight: 600 }}>Step1 UI Elements</div>
          </Col>
          <Col>
            <CommonButton
              onClick={() => {
                onAdd();
              }}
            >
              Add New UI Element
            </CommonButton>
          </Col>
        </Row>
        <div style={{ marginTop: 20 }}>
          <FormUiTable
            dataList={dataList}
            deleteData={deleteData}
            updateData={updateData}
          />
        </div>
      </Spin>
    </div>
  );
};
export default Step1FormTab;
