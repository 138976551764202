import React from "react";
import { Col, Divider, Empty, message, Row, Select, Spin } from "antd";
import "./ClubBoxWidget.css";
import { UpdateCurClub, UpdateCurRegistration } from "redux/actions/appActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { apiGetRegistrationList } from "services/dashboardService";
import { useState } from "react";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { useHistory } from "react-router";
import { ROUTE_HOME_SETTING } from "navigation/CONSTANTS";
const { Option } = Select;
const ClubBoxWidget = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);

  const { type, mClub, setMClub, mRegistration, setMRegistration, newReg } =
    props;

  const [clubList, setClubList] = useState([]);
  const [curClub, setCurClub] = useState({
    id: "",
    name: "",
  });
  const [registrationList, setRegistrationList] = useState([]);
  const [curRegistration, setCurRegistration] = useState({
    ID: "",
    registration_name: "",
  });

  useEffect(() => {
    setClubList([]);
    setRegistrationList([]);
    setCurClub({
      id: "",
      name: "",
    });
    setCurRegistration({
      ID: "",
      registration_name: "",
    });
    if (userDataStore != undefined && userDataStore.clubs != undefined) {
      let tmpList = [];
      userDataStore.clubs.map((item) => {
        if (item.CLUB_OR_TEAM != "0") {
          let club = { id: item.CLUB_ID, name: item.CLUB_TEAM_INFO.CLUB_NAME };
          tmpList = [...tmpList, club];
        }
      });

      setClubList(tmpList);
      if (appDataStore.club == undefined || type != undefined) {
        if (tmpList.length > 0) {
          setCurClub(tmpList[0]);
          if (type == undefined) {
            dispatch(UpdateCurClub({ club: tmpList[0] }));
          } else {
            setMClub(tmpList[0]);
          }

          getRegistrationList(tmpList[0].id);
        }
      } else {
        setCurClub(appDataStore.club);
        getRegistrationList(appDataStore.club.id);
      }
    }
  }, [userDataStore]);
  const getRegistrationList = (id) => {
    setRegistrationList([]);
    setCurRegistration({
      ID: "",
      registration_name: "",
    });
    const params = new URLSearchParams();
    params.append("token", userDataStore.token);
    params.append("club_id", id);
    apiGetRegistrationList(params)
      .then((res) => {
        setRegistrationList(res);
        if (res.length > 0) {
          setCurRegistration(res[0]);
          if (type == undefined) {
            dispatch(UpdateCurRegistration({ curRegistration: res[0] }));
          } else {
            setMRegistration(res[0]);
          }
        } else {
          if (type == undefined) {
            dispatch(UpdateCurRegistration({ curRegistration: undefined }));
          } else {
            setMRegistration({
              ID: "",
              registration_name: "",
            });
          }
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };
  return (
    <div className="club-box">
      <Row align={"bottom"} justify={"space-between"} gutter={[16, 16]}>
        <Col>
          <div>
            <span className="club-name" style={{ paddingRight: 32 }}>
              Club Name:
            </span>
            <Select
              className="select-club"
              style={{ width: 300 }}
              value={curClub == null ? null : curClub.name}
              onSelect={(v) => {
                const tmpClub =
                  clubList != undefined &&
                  clubList.length > 0 &&
                  clubList.find((x) => x.id == v);
                setCurClub(tmpClub);
                if (type == undefined) {
                  dispatch(UpdateCurClub({ club: tmpClub }));
                  dispatch(
                    UpdateCurRegistration({ curRegistration: undefined })
                  );
                } else {
                  setMClub(tmpClub);
                  setMRegistration({
                    ID: "",
                    registration_name: "",
                  });
                }

                getRegistrationList(tmpClub.id);
              }}
            >
              {clubList != undefined &&
                clubList.length > 0 &&
                clubList.map((clubInfo, index) => {
                  return (
                    <Option value={clubInfo.id} key={`${clubInfo.id}-${index}`}>
                      {clubInfo.name}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div style={{ marginTop: 20 }}>
            <span className="club-name">Registrations:</span>
            <Select
              className="select-club"
              style={{ width: 300 }}
              value={
                curRegistration == null
                  ? null
                  : curRegistration.registration_name
              }
              notFoundContent={
                <div>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Registrations Found"
                  ></Empty>
                </div>
              }
              onSelect={(v) => {
                const tmpRegs =
                  registrationList != undefined &&
                  registrationList.length > 0 &&
                  registrationList.find((x) => x.ID == v);
                if (type == undefined) {
                  dispatch(UpdateCurRegistration({ curRegistration: tmpRegs }));
                } else {
                  setMRegistration(tmpRegs);
                }

                setCurRegistration(tmpRegs);
              }}
            >
              {registrationList != undefined &&
                registrationList.length > 0 &&
                registrationList.map((regsInfo, index) => {
                  return (
                    <Option value={regsInfo.ID} key={`${regsInfo.ID}-${index}`}>
                      {regsInfo.registration_name}
                    </Option>
                  );
                })}
            </Select>
          </div>
        </Col>
        {newReg != undefined && (
          <Col>
            <div style={{ paddingRight: 20 }}>
              <CommonButton
                onClick={() => {
                  history.push(ROUTE_HOME_SETTING);
                }}
              >
                New Registration
              </CommonButton>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ClubBoxWidget;
