import React, { useEffect, useState } from "react";
import { confirmable } from "react-confirm";
import { Switch, Row, Col, Select, message, Input, InputNumber, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import "./FormUiAddModal.css";
import { isEmpty } from "utils/GlobalFunctions";
import { apiAddUiForm } from "services/dashboardService";
const { Option } = Select;
const { TextArea } = Input;
function FormUiAddModal(props) {
  const { show, proceed, title, token, registrationID, info, columns } = props;
  const [uiData, setUiData] = useState({ name: "", hint_text: "", reg_column_name: "", group_title: "", order: "", ui_type: "", dropdown_list: "", is_required: true, is_encrypt: false, is_filepicker: false });
  const [errorField, setErrorField] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    if (info != undefined) {
      setUiData({ ...uiData, ...info, is_required: info.is_required === "true", is_encrypt: info.is_encrypt === "true", is_filepicker: info.is_filepicker === "true" })
    }
  }, [info])
  const validateFields = () => {
    let errorList = Array();
    errorList = isEmpty(uiData, "name", errorList);
    errorList = isEmpty(uiData, "reg_column_name", errorList);
    errorList = isEmpty(uiData, "group_type", errorList);
    errorList = isEmpty(uiData, "group_title", errorList);
    errorList = isEmpty(uiData, "order", errorList);
    errorList = isEmpty(uiData, "ui_type", errorList);
    if (uiData?.ui_type == "3") {
      errorList = isEmpty(uiData, "dropdown_list", errorList);
    }
    setErrorField(errorList)
    return errorList.length == 0;
  };

  const onCreate = () => {
    if (!validateFields()) return;

    const params = new URLSearchParams();
    params.append("token", token);
    params.append("registration_id", registrationID);
    params.append("name", uiData.name);
    params.append("hint_text", uiData.hint_text);
    params.append("reg_column_name", uiData.reg_column_name);
    params.append("group_title", uiData.group_title);
    params.append("group_type", uiData.group_type);
    params.append("ui_type", uiData.ui_type);
    params.append("order", uiData.order);
    params.append("is_required", uiData.is_required);
    params.append("is_encrypt", uiData.is_encrypt);
    params.append("dropdown_list", uiData.dropdown_list);
    params.append("is_filepicker", uiData.is_filepicker);
    if (info != undefined) {
      params.append("id", info.id);
    }
    setIsBusy(true);
    apiAddUiForm(params)
      .then((res) => {
        setIsBusy(false);
        if (res) {
          if (info != undefined) {
            message.success("Updated Successfully");
          } else {
            message.success("Added Successfully");
          }

          proceed(true)
        }
      })
      .catch((err) => {
        setIsBusy(false);
        message.error(err);
      });

  };
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="message-one-dlg"
      closable={true}
      maskClosable={false}
      title={title}
      okText={info == undefined ? "Add" : "Save"}
      onOk={onCreate}
    >
      <Spin spinning={isBusy}>
        <Row style={{ marginTop: 20 }} gutter={[16, 16]}>
          <Col md={12}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>Name*</div>
            <Input
              placeholder="Label Over text box"
              value={uiData?.name}
              className={
                errorField !== undefined &&
                  errorField.includes("name")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("name")) {
                  let errors = errorField.filter(
                    (y) => y != "name"
                  );
                  setErrorField([...errors]);
                }
                setUiData({ ...uiData, name: e.target.value })
              }}

            />
          </Col>
          <Col md={12}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>Hint Text</div>
            <Input
              className="common-input"
              placeholder="Hint text in text box"
              value={uiData?.hint_text}
              onChange={(e) => {
                setUiData({ ...uiData, hint_text: e.target.value })
              }}
            />
          </Col>
          <Col md={12}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>Reg Column Name*</div>
            {/* <Input
              placeholder="Corresponding column name in camp_registration_users"
              value={uiData?.reg_column_name}
              className={
                errorField !== undefined &&
                  errorField.includes("reg_column_name")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("reg_column_name")) {
                  let errors = errorField.filter(
                    (y) => y != "reg_column_name"
                  );
                  setErrorField([...errors]);
                }
                setUiData({ ...uiData, reg_column_name: e.target.value })
              }}
            /> */}
            <Select
              showSearch
              placeholder="Corresponding column name in camp_registration_users"
              className={
                errorField !== undefined && errorField.includes("reg_column_name")
                  ? `common-select-error`
                  : `common-select`
              }
              value={uiData?.reg_column_name || undefined}
              onSelect={(v) => {
                if (errorField.includes("reg_column_name")) {
                  let errors = errorField.filter((x) => x != "reg_column_name");
                  setErrorField([...errors]);
                }
                setUiData({ ...uiData, reg_column_name: v });
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {columns.map((fieldItem, fIndex) => {
                return <Option key={fIndex} value={fieldItem.Field}>{fieldItem.Field}</Option>
              })}
            </Select>
          </Col>
          <Col md={12}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>Group Type*</div>
            <Select
              showSearch
              className={
                errorField !== undefined && errorField.includes("group_type")
                  ? `common-select-error`
                  : `common-select`
              }
              value={uiData?.group_type || undefined}
              placeholder="Group Type"
              onSelect={(v) => {
                if (errorField.includes("group_type")) {
                  let errors = errorField.filter((x) => x != "group_type");
                  setErrorField([...errors]);
                }
                setUiData({ ...uiData, group_type: v });
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={"1"}>Participant Info Form</Option>
              <Option value={"2"}>Parent/Guardian Info Form</Option>
              <Option value={"3"}>Emergency Contact Form</Option>
              <Option value={"4"}>Other Info Form</Option>
            </Select>
          </Col>
          <Col md={12}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>Group*</div>
            <Input
              placeholder="Group Title"
              value={uiData?.group_title}
              className={
                errorField !== undefined &&
                  errorField.includes("group_title")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("group_title")) {
                  let errors = errorField.filter(
                    (y) => y != "group_title"
                  );
                  setErrorField([...errors]);
                }
                setUiData({ ...uiData, group_title: e.target.value })
              }}
            />
          </Col>
          <Col md={12}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>Order*</div>
            <InputNumber
              min={1}
              max={100}
              placeholder="location of text box within group"
              size="small"
              style={{ width: '100%' }}
              value={uiData?.order}
              className={
                errorField !== undefined &&
                  errorField.includes("order")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("order")) {
                  let errors = errorField.filter(
                    (y) => y != "order"
                  );
                  setErrorField([...errors]);
                }
                setUiData({ ...uiData, order: e })
              }}
              step={1}
            />
          </Col>
          <Col md={12}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>UI Type*</div>
            <Select
              showSearch
              className={
                errorField !== undefined && errorField.includes("ui_type")
                  ? `common-select-error`
                  : `common-select`
              }
              value={uiData?.ui_type || undefined}
              placeholder="UI Type"
              onSelect={(v) => {
                if (errorField.includes("ui_type")) {
                  let errors = errorField.filter((x) => x != "ui_type");
                  setErrorField([...errors]);
                }
                setUiData({ ...uiData, ui_type: v });
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={"1"}>Text</Option>
              <Option value={"2"}>Number</Option>
              <Option value={"3"}>Dropdown</Option>
              <Option value={"4"}>Phone Number</Option>
              <Option value={"5"}>Button</Option>
              <Option value={"6"}>Date</Option>
            </Select>
          </Col>
          <Col md={12}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>Required Field</div>
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={uiData?.is_required}
              style={{ marginBottom: 10 }}
              onChange={(e) => {
                setUiData({ ...uiData, is_required: e });
              }}
            />
          </Col>
          {uiData?.ui_type == "5" && <Col md={12}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>File Picker</div>
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={uiData?.is_filepicker}
              style={{ marginBottom: 10 }}
              onChange={(e) => {
                setUiData({ ...uiData, is_filepicker: e });
              }}
            />
          </Col>}

          <Col md={12}>
            <div style={{ paddingBottom: "5px", fontWeight: 600 }}>Encrypt Text</div>
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={uiData?.is_encrypt}
              style={{ marginBottom: 10 }}
              onChange={(e) => {
                setUiData({ ...uiData, is_encrypt: e });
              }}
            />
          </Col>
          {uiData?.ui_type == "3" && <Col md={24}>
            <div style={{ paddingBottom: "2px", fontWeight: 600 }}>Dropdown List*</div>
            <div style={{ paddingBottom: "5px", fontWeight: 600, fontSize: 12 }}>* NOTE - Dropdown List should be comma separated.  ex: K, 1, 2</div>
            <TextArea
              rows={6}
              value={uiData?.dropdown_list}
              className={
                errorField !== undefined && errorField.includes("dropdown_list")
                  ? `common-input-error`
                  : `common-input`
              }
              onChange={(e) => {
                if (errorField.includes("dropdown_list")) {
                  let errors = errorField.filter((y) => y != "dropdown_list");
                  setErrorField([...errors]);
                }
                setUiData({ ...uiData, dropdown_list: e.target.value });
              }}
            />
          </Col>}
        </Row>
      </Spin>
    </Modal>
  );
}

export default confirmable(FormUiAddModal);
